import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default () => {
  const { i18n } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        french: markdownRemark(
          frontmatter: { title: { eq: "legal" }, langKey: { eq: "fr" } }
        ) {
          id
          excerpt(pruneLength: 10000, format: HTML)
        }
        english: markdownRemark(
          frontmatter: { title: { eq: "legal" }, langKey: { eq: "en-US" } }
        ) {
          id
          excerpt(pruneLength: 10000, format: HTML)
        }
      }
    `
  );
  const lang = i18n.language;
  const dataHtml = lang === "fr" ? data.french : data.english;

  return (
    <div className="legal-page">
      <Helmet title="Mentions légales" />
      {parse(dataHtml.excerpt)}
    </div>
  );
};
